<template>
  <div class="con">
    <img src="../../img/kcl2.png" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss"  scoped>
.con{
    // border: 1px solid red;

    img{
        width: 100%;
        height: auto;
    }
}
</style>